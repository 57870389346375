/**
 * Fade In Component
 *
 * @usageNotes
 *
 * ```tsx
 *
 * // Wrap whatever elements you want in a `<FadeIn>` wrapper:
 * <FadeIn>
 *   <SomeBitOfUI />
 * </FadeIn>
 *
 * // Render conditionally to fade on every re-enter
 * {someBoolean && (
 *   <FadeIn>
 *     <ThingToShow />
 *   </FadeIn>
 * )}
 *
 * // Customize `duration` and `delay`
 * <FadeIn delay={250} duration={450}>
 *   <p>Slightly later and longer animation</p>
 * </FadeIn>
 *
 * ```
 */

import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

interface FadeInProps {
  duration?: number;
  delay?: number;
  style?: object;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  @media (prefers-reduced-motion: no-preference) {
    animation-name: ${fadeIn};
    animation-fill-mode: backwards;
  }
`;

const FadeIn: FC<FadeInProps> = ({ duration = 300, delay = 0, children, ...delegated }) => {
  return (
    <Wrapper
      {...delegated}
      style={{
        ...(delegated.style || {}),
        animationDuration: duration + 'ms',
        animationDelay: delay + 'ms',
      }}
    >
      {children}
    </Wrapper>
  );
};

export default FadeIn;

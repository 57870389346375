import dev from './development';
import test from './test';
import prod from './production';

const ENV = process.env.NODE_ENV;

function setEnv() {
  switch (ENV) {
    case 'development':
      return dev;
    case 'test':
      return test;
    case 'production':
      return prod;
    default:
      return dev;
  }
}

export default setEnv();

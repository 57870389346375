/**
 * Documentation
 * @link https://developers.convertkit.com/#add-subscriber-to-a-form
 */
import axios, { AxiosError } from 'axios';

import config from 'config';

axios.interceptors.response.use(undefined, (error: AxiosError<{ message: string }>) => {
  if (!error.isAxiosError) {
    return Promise.reject(error);
  }

  return Promise.reject(
    new Error(error.response?.data.message || 'Sorry, an error occurred while subscribing.')
  );
});

interface ConvertKitSubscribe {
  email: string;
  api_key: string;
  tags?: number[];
}

export async function subscribe(email: string) {
  const data: ConvertKitSubscribe = {
    email,
    api_key: config.convertkit.apiKey,
    tags: config.convertkit.tags,
  };

  await axios.post(`https://api.convertkit.com/v3/forms/2261423/subscribe`, data);
}

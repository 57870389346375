/* eslint-disable import/no-anonymous-default-export */
import environment from './environments';

export default {
  environment,
  convertkit: {
    apiKey: process.env.REACT_APP_CONVERTKIT_API_KEY as string,
    tags: [2366452],
  },
};

import React, { FormEvent, useState, useRef } from 'react';
import classNames from 'classnames';

import FadeIn from 'components/FadeIn';
import { subscribe } from 'api/convertkit';

import './icons/font-awesome.css';
import './icons/linea.css';
import './ComingSoon.scss';

function ComingSoon() {
  const [email, setEmail] = useState('');
  const [hasFocus, setHasFocus] = useState(false);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [isMessageVisible, setIsMessageVisible] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const hideMessage = (timeInMs: number): void => {
    setTimeout(() => {
      setIsMessageVisible(false);
      setStatus('');
    }, timeInMs);
  };

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    subscribe(email?.toLowerCase())
      .then(() => {
        setStatus('success');
        setMessage('Thank you for subscribing!');
        hideMessage(3000);
      })
      .catch((err) => {
        setStatus('error');
        setMessage(err.message);
        hideMessage(5000);
      })
      .finally(() => {
        setEmail('');
        setIsMessageVisible(true);
      });
  }

  function handleClick() {
    if (formRef.current?.reportValidity()) {
      formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }

  return (
    <div data-spy="scroll" data-target=".scrollspy" className="coming-soon">
      <div className="loader">
        <div className="loader-inner ball-scale-ripple-multiple ball-scale-ripple-multiple-color">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div id="page">
        <div className="section-overlay overlay-opacity"></div>

        <div
          id="modal-notify"
          className="modal fade modal-scale"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="meinModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div>
              <div className="modal-content text-center text-light">
                <button className="button-close" data-dismiss="modal" aria-label="Close">
                  <i className="icon icon-lg icon-arrows-remove"></i>
                </button>

                <div className="wrap-line border-dark">
                  <h3>
                    <span className="font-weight-200">Stay</span> Tuned
                  </h3>
                </div>

                <div className="p-t-b-15">
                  <p>
                    We'll be launching our new website soon.
                    <br />
                    Please stay updated and follow.
                  </p>
                </div>

                <div className="p-t-b-30">
                  <form onSubmit={handleSubmit} id="mc-form" noValidate>
                    <div
                      className={classNames('input-group', {
                        focus: hasFocus,
                        error: status === 'error',
                      })}
                    >
                      <input
                        value={email}
                        onFocus={() => setHasFocus(true)}
                        onBlur={() => setHasFocus(false)}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        id="email"
                        className="form-control form-control-light"
                        name="email"
                        placeholder="Enter your email here..."
                        autoFocus
                      />
                      <span className="input-group-btn">
                        <button onClick={handleClick} type="submit" className="btn btn-color">
                          <i className="icon icon-sm icon-arrows-slim-right-dashed"></i>
                        </button>
                      </span>
                    </div>

                    {isMessageVisible && (
                      <FadeIn duration={300}>
                        <div
                          id="message-newsletter"
                          className={classNames('message-wrapper text-white message', {
                            error: status === 'error',
                          })}
                        >
                          <span>
                            <label
                              className="message-text"
                              htmlFor="email"
                              dangerouslySetInnerHTML={{ __html: message }}
                            ></label>
                          </span>
                        </div>
                      </FadeIn>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <header>
          <nav className="navbar navbar-fixed-top">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 text-white col-transform">
                  <div className="navbar-wrapper">
                    <button className="navbar-button show-info" data-href="#content">
                      <span></span>
                      <span></span>
                      <span></span>
                    </button>

                    <div className="navbar-links scrollspy">
                      <ul className="nav">
                        <li>
                          <a className="smooth-scroll link-white" href="#about">
                            About
                          </a>
                        </li>
                        <li>
                          <a className="smooth-scroll link-white" href="#contact">
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header> */}

        <div className="container-fluid">
          <div className="row">
            <div id="info" className="col-md-12 text-white text-center page-info col-transform">
              <div className="vert-middle">
                <div className="reveal scale-out">
                  <div className="p-t-b-15">
                    <img src={process.env.PUBLIC_URL + '/logo512.png'} className="logo" alt="" />
                  </div>

                  <div className="p-t-b-15">
                    <h2 className="headline">
                      <span className="font-weight-200">We're soon launching our website</span>
                      <br />
                      <br />
                      Dates to Remember
                      <span className="tagline">"Never miss a moment to show them you care"</span>
                    </h2>

                    <p className="verbiage font-weight-200">
                      Coming soon! Dates to Remember will be here for you to ensure you never forget
                      another important date of your loved ones, like birthdays, anniversaries, and
                      more again!
                    </p>
                  </div>
                  <div className="p-t-b-20">
                    <i className="icon icon-sm icon-arrows-slim-down-dashed"></i>
                  </div>
                  <div className="p-t-b-15 btn-row">
                    <button
                      className="btn btn-color"
                      data-toggle="modal"
                      data-target="#modal-notify"
                    >
                      Notify me
                    </button>
                    {/* <button className="btn btn-border-white show-info" data-href="#content">
                      More info
                    </button> */}
                  </div>
                </div>
              </div>
            </div>

            {/* <div id="content" className="page-content col-md-6 text-center">
              <section id="about" className="p-t-b-30">
                <div className="row">
                  <div className="col-sm-8 col-sm-offset-2">
                    <div className="wrap-line border-dark">
                      <h3>
                        <span className="font-weight-200">About</span> Us
                      </h3>
                    </div>
                    <div className="p-t-b-15  text-grey">
                      <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing.
                        <br />
                        Elitr sed diam nonumy eirmod, tempor invidunt.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section id="contact" className="p-t-b-30">
                <div className="row">
                  <div className="col-sm-8 col-sm-offset-2">
                    <div className="wrap-line border-dark">
                      <h3>
                        <span className="font-weight-200">Our</span> Mission
                      </h3>
                    </div>
                    <div className="p-t-b-15  text-grey">
                      <p>
                        We launch our new website soon.
                        <br />
                        Please stay updated and follow.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
